.resume {
  text-align: left;
  width: 60%;
  padding:  3vh 3vw;
  overflow: auto;
  overflow-y: auto;
}

a {
  text-decoration: underline;
  color: #c8d1d9;
}

a:hover {
  opacity: .3;
  transition: all .5s ease;
  -webkit-transition: all .5s ease;
}

p > a:focus {
  outline: none;
  box-shadow: 0 0 3px 3px #c8d1d9;
}

#download {
  border: 5px solid #c8d1d9;
  padding: 5px;
  text-decoration: none;
}

#download:focus {
  outline: none;
  box-shadow: 0 0 3px 3px #c8d1d9;
}

#covid {
  font-size: 10px;
}

@media only screen and (max-width: 600px) {
  .resume {
    width: 90%;
    max-height: 50vh;
  }
}

.App {
  text-align: center;
}

/* header */

.App-header {
  min-height: 10vh;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
  grid-template-rows: repeat(1, 50px);
  font-size: calc(1px + 2.5vmin);
  color: #c8d1d9;
}

.App-header > .btn {
  padding: 1vh 1vw;
  cursor: pointer;
}

.App-header > .btn:hover {
  opacity: .3;
  transition: all .5s ease;
  -webkit-transition: all .5s ease;
}

:focus > .btn_content {
  box-shadow: 0 0 3px 3px #c8d1d9;
}

:focus > .btn_content_reversed {
  box-shadow: 0 0 3px 3px #0d1117;
}

.btn:focus,
.btn_content:focus,
.btn_content_reversed:focus {
  outline: none;
}

/* body */

.stuff {
  min-height: 50vh;
}

/* footer */

.App-footer {
  display: flex;
  flex-direction: column;
  justify-content: right;
  text-align: right;
  align-content: right;
  bottom: 0;
  right: 4em;
  position: fixed;
}

.App-footer > a {
  padding: 3vw .5vw;
  transition: all .5s ease;
  -webkit-transition: all .5s ease;
}

.App-footer > a:hover {
  opacity: .5;
}

.App-footer > a:focus {
  box-shadow: none;
}

#allMe, .social {
  text-align: right;
  margin-right: 1vw;
  float: right;
}

.social > a {
  padding: .5em;
}

.social > a > svg > path {
  fill: #c8d1d9;
}

.name, .name:hover {
  font-size: 10vmin;
  cursor: default;
  color: #c8d1d9;
  opacity: 1;
  margin-top: -8px;
  margin-bottom: -10px;
}

#title, #title:hover {
  font-size: calc(5px + 2vmin);
  cursor: default;
  color: #c8d1d9;
  opacity: 1;
  margin-top: 3px;
  margin-bottom: -6px;
}

#copy {
  font-size: 12px;
}

/* media queries */

@media only screen and (max-width: 600px) {
  #bio {
    width: 90%;
    max-height: 50vh;
  }
}

@media only screen and (max-width: 500px) {
  .hide {
    display: none;
  }
}

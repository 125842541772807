.contact_container {
  padding: 3em;
  margin: 0 3vw;
  overflow: none;
}

input[type=text], input[type=email], textarea {
  width: 50%; /* Full width */
  padding: 1em; /* Some padding */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 1em; /* Add a top margin */
  margin-bottom: 1em; /* Bottom margin */
  resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
  background-color: #e0e5e9;
  font-family: 'Atkinson Hyperlegible';
}

input[type=text]:focus {
  outline: none;
  box-shadow: 0 0 3px 3px #c8d1d9;
}

input[type=email]:focus {
  outline: none;
  box-shadow: 0 0 3px 3px #c8d1d9;
}

textarea:focus {
  outline: none;
  box-shadow: 0 0 3px 3px #c8d1d9;
}

label {
  font-weight: 900;
}

input[type=submit] {
  background: none;
  color: #c8d1d9;
  border: 5px solid #c8d1d9;
  padding: 5px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  transition: all .5s ease;
  -webkit-transition: all .5s ease;
  font-style: italic;
  font-weight: 900;
  z-index: 2;
}

input[type=submit]:hover {
  opacity: .3;
}

input[type=submit]:focus {
  outline: none;
  box-shadow: 0 0 3px 3px #c8d1d9;
}

#anotherOne {
  color: #c8d1d9;
  border: 5px solid #c8d1d9;
  padding: 5px;
  cursor: pointer;
  text-decoration: none;
}

/* media queries */

@media only screen and (max-width: 900px) {
  input[type=text], input[type=email], textarea {
    width: 70%;
  }
}

@media only screen and (max-width: 600px) {
  input[type=text], input[type=email], textarea {
    width: 100%;
  }
}
.container {
  margin: 5em 0;
}

.projects {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(3, minmax(325px, 1fr));
  grid-template-rows: repeat(1, 250px);
  margin: 10vh 0;
}

@media only screen and (max-height: 900px) {
.projects {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  grid-template-rows: repeat(1, 150px);
  margin: 10vh 0;
  }
}

@media only screen and (max-width: 600px) {
  .projects {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(3, minmax(80px, 1fr));
    grid-template-rows: repeat(1, 80px);
    margin: 10vh 0;
  }
}

.work-section {
  min-height: 3vh;
}

.projects > div > img  {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  cursor: pointer;
  overflow: hidden;
}

.projects > div > img:hover {
  filter: none;
  -webkit-filter: grayscale(0%);
}

.show {
  margin: 0 15vw;
  width: 35%;
}

#showText {
  float: left;
  padding: 1em;
  text-align: left;
  font-weight: 500;
  font-size: 1.5em;
  overflow: auto;
  overflow-y: auto;
}

@media only screen and (max-width: 600px) {
  .show {
    width: 70%;
  }
  #showText {
    max-height: 35vh;
  }
}

#x {
  float: right;
  cursor: pointer;
  font-style: normal;
  font-weight: 900;
}

#x:hover {
  opacity: .3;
  transition: all .5s ease;
  -webkit-transition: all .5s ease;
}

#me {
  width:  28%;
  height: 28%;
  float: left;
  margin: 1vh 4vw;
  border-radius: 50%;
  filter: none;
  -webkit-filter: grayscale(0%);
}

#me:hover {
  transform: rotateY(180deg);
  cursor:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>✨</text></svg>") 16 0,auto; /*emojicursor.app*/
}


#bio {
  float: left;
  font-size: calc(10px + 2vmin);
  margin: 1vh 4vw;
  width: 40%;
  text-align: left;
  overflow: auto;
  overflow-y: auto;
  max-height: 60vh;
}

@media only screen and (max-width: 600px) {
  #bio {
    width: 90%;
    max-height: 50vh;
  }
}

.about {
  margin: 5vh 7vw;
}

h3  {
  margin-bottom: 0;
}
